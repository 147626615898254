import React from "react"
import Layout from "@components/layout"
import { graphql, Link } from "gatsby"
import SEO from "@components/seo"
import marked from "marked"
import PageHeadingTitle from "@components/pageHeadingTitle"

const ApplicationTemplate = ({ data }) => {
  const {
    application,
    applicationList,
    sanityapplication,
    sanityProductType,
  } = data

  return (
    <Layout>
      <SEO
        title="Products | Information Center"
        description={data.site.siteMetadata.description}
      />
      <div className="page-headline">
        <div className="container">
          <PageHeadingTitle h1={"Products"} h2={sanityapplication.title}/>
        </div>
      </div>
      <div className="page-body fesProduct">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-12">
              <div className="side-content text-center">
                <h4 className="font-weight-bold text-primary">
                  Browse by Application
                </h4>
                <ul className="list-inline application">
                  <li className="list-inline-item my-2">
                    <Link to="/products">
                      <span className="app-fontsize px-3 py-2 bg-light rounded-sm">All</span>
                    </Link>
                  </li>
                  {applicationList.nodes.map(app => (
                    <li key={app.id} className="list-inline-item my-2">
                      <Link to={`/products/${app.slug.current}`}>
                        {" "}
                        <span className="app-fontsize px-3 py-2 bg-light rounded-sm">
                          {app.title}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
                {/* <div>
                  <p className="text-left">{application.nodes[0].excerpt}</p>
                </div> */}
                <div
                  className="container text-left my-5"
                  dangerouslySetInnerHTML={{
                    __html: marked(application.nodes[0].excerpt || ""),
                  }}
                />
              </div>

              <div className="row">
                {/* {sanityapplication.productType.map(type => (
                  <Link to={`/${type.slug.current}`}>
                    <h3>{type.title}</h3>
                  </Link>
                ))} */}
                {sanityapplication.productType.map(type => (
                  <div
                    className="col-md-4 mb-5 text-left"
                    key={type.slug.current}
                  >
                    <div className="blog-item bg-light rounded border">
                      <Link to={`/` + type.slug.current}>
                        <div className="blog-img-tile">
                          <div className="tile-bg-wrapper">
                            <span className="doc-icon">
                              <i className="fa fa-file-text"></i>
                            </span>
                            <div
                              className="tile-bg rounded-top"
                              style={{
                                backgroundImage: `url(${(type &&
                                  type.mainImage &&
                                  type.mainImage.asset &&
                                  type.mainImage.asset.fluid &&
                                  type.mainImage.asset.fluid.src) ||
                                  require("@images/no-image-placeholder.jpg")})`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </Link>
                      <div className="my-3 px-3">
                        <Link to={`/` + type.slug.current} className="h4">
                          {type.title}
                        </Link>
                        <p className="small mt-2 mb-0">{type.excerpt}</p>
                      </div>
                      <Link
                        to={`/` + type.slug.current}
                        className="btn btn-secondary btn-secondary-red btn-sm m-3"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ApplicationTemplate

export const applicationQuery = graphql`
  query ApplicationTemplateQuery($id: String!) {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    application: allSanityApplication(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        excerpt
        slug {
          current
        }
      }
    }
    applicationList: allSanityApplication {
      nodes {
        id
        title
        excerpt
        slug {
          current
        }
      }
    }

    sanityapplication: sanityApplication(id: { eq: $id }) {
      id
      title
      excerpt
      productType {
        id
        title
        slug {
          current
        }
        mainImage {
          asset {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
